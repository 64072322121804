import { StyleSheet } from "react";

const StoryCard = ({
  imgURL,
  onClick,
  playing,
  currentTrack,
  trackId,
  title,
  theme,
  className,
}) => {
  return (
    <div className={`audio-card ${className ? className : ""}`}>
      <div
        className={`story-card ${className ? className : ""}`}
        onClick={onClick}
        style={{
          backgroundImage: "url(" + imgURL + ")",
          backgroundSize: "cover",
         
        }}
      >
        <div
          className={
            playing && currentTrack === trackId
              ? "pause-container"
              : "play-container"
          }
          style={{
            backgroundColor: "rgba(0,0,0,0.5)",
            width: 50,
            height: 50,
            display: "flex",
            borderRadius: "50%",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {playing && currentTrack === trackId ? (
            <i className="fa fa-pause play-icon"></i>
          ) : (
            <i className="fa fa-play play-icon"></i>
          )}
        </div>
      </div>
      <div className={"d-flex "+  (className == "big" ? "justify-content-center  mt-4" : "justify-content-start mt-2")}>
        <h5
          className={
            "story-card-title " +
            (theme == "light" ? "light-text" : "dark-text") +
            (className == "big" ? "" : " landing-card-title")
          }
        >
          {title}
        </h5>
      </div>
    </div>
  );
};

export default StoryCard;
