const RegisterBig = ({ type }) => {
  return (
    <>
      <div className="mobile">
        <div className="row">
          <div className="w-100 d-flex justify-content-start flex-column align-items-center align-content-center text-center">
            <a
              className="btn btn-download"
              href="/auth/register"
              data-attr="register_header"
            >
              რეგისტრაცია
            </a>
          </div>
        </div>
      </div>
      <div className="desktop">
        <div className="row">
          <div className="col d-flex py-5 flex-column justify-content-start align-items-center align-content-center">
            {/* <h2 className="hero-text" style={{ color: "#373985" }}>
             დარეგისტრირდი
            </h2> */}
            <a
              className="btn btn-download"
              href="/auth/register"
              data-attr="register_header"
            >
              მოუსმინე ზღაპრებს შეზღუდვის გარეშე
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegisterBig;
