import { StyleSheet } from "react";

const BenefitCard = ({ onClick, title, subtitle, degree, index }) => {
  return (
    <div
      key={index}
      className="benefit-card"
      style={{ transform: "rotate(" + degree + "deg)" }}
    >
      <div
        className=""
        onClick={onClick}
        style={{
          textAlign: "center",
          // verticalAlign: "middle",
          marginLeft: 30,
          marginRight: 30,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          alignContent: "center",
          borderRadius: 30,
          boxShadow: "0px 0px 40px 0px rgba(0,0,0,0.5)",
          backgroundSize: "contain",
          width: 260,
          height: 260,
          backgroundColor: "#BFBCF1",
          padding: 20,
          //   cursor: "pointer",
        }}
      >
        <h3 className="white">{title}</h3>
        <h3 className="dark-text">{subtitle}</h3>
      </div>
    </div>
  );
};

export default BenefitCard;
