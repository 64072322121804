const Download = () => {
  return (
    <>
      <div className="mobile">
        <a
          href="https://link.bulbuli.ge/web"
          target="_blank"
          className="btn btn-download"
        >
          გადმოწერეთ აპლიკაცია
        </a>
      </div>
      <form className="mt-5 center desktop">
        <p>გადმოწერე აპლიკაცია</p>
        <div className="form-row">
          <div className="col-auto">
            <a
              href="https://play.google.com/store/apps/details?id=ge.istoriebi_new.mchost"
              target="_blank"
              style={{ marginRight: 10 }}
            >
              <img
                src="images/play_store.png"
                className="img-download"
                alt="play store logo"
              />
            </a>

            <a
              href="https://apps.apple.com/us/app/bulbuli/id1540480146"
              target="_blank"
            >
              <img
                src="images/app_store.png"
                className="img-download"
                alt="app store logo"
              />
            </a>
          </div>
        </div>
      </form>
    </>
  );
};

export default Download;
