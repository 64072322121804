const CollectionCard = ({ imgURL, title, stories }) => {
  return stories.map((story) => (
    <div
    
      key={story.id}
      style={{
        paddingLeft: 10,
        paddingRight: 10,
        paddingBottom: 5,
        paddingTop: 5,
        borderRadius: 20,
        backgroundColor: "#373985",
        margin: 5,
      }}
    >
      <span
       
        style={{
          //marginLeft: 10,
          //color: "#a39fde",
          //color: "#373985",
          textOverflow: "ellipsis",
          width: 50,
          //whiteSpace: "nowrap",
          overflow: "hidden",
        }}
      >
        {story.title}
      </span>
    </div>
  ));
};
export default CollectionCard;
